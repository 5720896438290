import { Divider, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Rooftop } from 'shared/interfaces/rooftop.interface';
import ToggleSwitch from 'components/shared/ToggleSwitch';

interface RooftopPreferencesProps {
  rooftop: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const RooftopPreferences = ({
  rooftop,
  handleRooftopChange
}: RooftopPreferencesProps): ReactElement => {
  const updatePreference = (e: any, key: string): void => {
    const newRooftopPreferences = {
      ...rooftop.preferances,
      [key]: e.target.checked
    }

    handleRooftopChange('preferances', newRooftopPreferences)
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>Preference Settings</Typography>
      <Divider />
      <br />
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.use_nhtsa_database}
          onChange={(e: any) => handleRooftopChange('use_nhtsa_database', e.target.checked)}
          label="Use NHTSA Database (VIN Decoding Database)" />
      </div>
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.preferances.skipExternal}
          onChange={(e: any) => updatePreference(e, "skipExternal")}
          label="Allow skipping of external photos." />
      </div>
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.preferances.skipPano}
          onChange={(e: any) => updatePreference(e, "skipPano")}
          label="Allow skipping of interior photos." />
      </div>
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.preferances.vinchar}
          onChange={(e: any) => updatePreference(e, "vinchar")}
          label="Remove I's and Q's from Vin." />
      </div>
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.preferances.vinBypass}
          onChange={(e: any) => updatePreference(e, "vinBypass")}
          label="Show toggle that allows Vin length requirement bypass." />
      </div>
      <div className='mb-3'>
        <ToggleSwitch
          checked={rooftop.allow_cancel_vehicle}
          onChange={(e: any) => handleRooftopChange('allow_cancel_vehicle', e.target.checked)}
          label="Allow cancel vehicle on mobile" />
      </div>
    </>
  );
};

export default RooftopPreferences;
