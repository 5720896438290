import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { State } from "state/reducers";
import RooftopDetails from "./RooftopDetails";
import { bindActionCreators } from "redux";
import { create_rooftop, delete_rooftop, manipulate_rooftops, update_rooftop } from "state/rooftop/action_creator";

function RooftopSelector() {
    const rooftops: Rooftop[] = useSelector((state: State) => state.rooftop.rooftops)
    const [rooftopSelection, setRooftopSelection] = useState<number>(rooftops[0].id)
    const [rooftopState, setRooftopState] = useState<Rooftop>(rooftops[0])
    const dispatch = useDispatch()
    const createAction = bindActionCreators(create_rooftop, dispatch)
    const deleteAction = bindActionCreators(delete_rooftop, dispatch)
    const saveAction = bindActionCreators(update_rooftop, dispatch)
    const manipulateRooftopsAction = bindActionCreators(manipulate_rooftops, dispatch)
    const handleChange = (event: SelectChangeEvent) => {
        setRooftopSelection(+event.target.value as number)
    }

    useEffect(() => {
        manipulateRooftopsAction(rooftops)
    }, [])

    useEffect(() => {
        const rooftopToSelect = rooftops.find((rooftop: Rooftop) => rooftop.id === rooftopSelection) || rooftops[0]
        setRooftopState(rooftopToSelect)
    }, [rooftopSelection])

    const handleRooftopChange = (key: string, value: any) => {
      const newRooftopState = {
        ...rooftopState,
        [key]: value
      }
      
      setRooftopState(newRooftopState)
    };

    const handleWizardChange = (enabledList: any, disabledList: any) => {
      const newRooftopState = {
        ...rooftopState,
        'enabled': enabledList,
        'disabled': disabledList
      }
      
      setRooftopState(newRooftopState)
    };

    const handleSaveRooftop = () => {
        saveAction(rooftopState)
    }

    const handleDeleteRooftop = () => {
        if (rooftops.length === 1) {
            return
        }

        if (rooftopState) {
            deleteAction(rooftopState)
            const newSelection = rooftops.find((rooftop: Rooftop) => rooftop.name !== rooftopState.name)?.id || rooftops[0].id
            setRooftopSelection(newSelection)
        }
    }

    const handleCreateNewRooftop = () => {
        if (rooftopState) {
            const clone = { ...rooftopState }
            clone.name = `${rooftopState.name} ${rooftops.length}`
            clone.is_default = false
            clone.is_display_tablet_rooftop = false
            createAction(clone)
        }
    }

    return (
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: { xs: '100%', md: '66.67%' } }}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="rooftop-select-label">Rooftop</InputLabel>
                    <Select
                        labelId="rooftop-select-label"
                        value={rooftopState.id.toString()}
                        label="Rooftop"
                        onChange={handleChange}
                    >
                        {rooftops.map((rooftop: Rooftop) =>
                            <MenuItem key={rooftop.id} value={rooftop.id}>{rooftop.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button onClick={handleCreateNewRooftop} variant="contained" fullWidth>Duplicate Rooftop</Button>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button onClick={handleDeleteRooftop} variant="contained" fullWidth>Delete Rooftop</Button>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button onClick={handleSaveRooftop} variant="contained" fullWidth>Save Rooftop</Button>
            </Grid>
            <Grid item xs={12}>
                <RooftopDetails rooftopState={rooftopState} handleRooftopChange={handleRooftopChange} handleWizardChange={handleWizardChange}/>
            </Grid>
        </Grid>
    )
}

export default RooftopSelector;
