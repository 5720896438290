import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { OverlayDTO, RooftopOverlay } from '../overlay/canvas/table/OverlayTable';
import { OverlayCard } from './OverlayCard';
import { Typography, Divider } from '@mui/material';
import { State } from "state/reducers";

interface OverlaySettingsProps {
    rooftop: any
    handleRooftopChange: (key: string, value: any) => void
}

const OverlaySettings = ({ rooftop, handleRooftopChange }: OverlaySettingsProps) => {
    const overlays = useSelector((state: State) => state.overlays.overlays) as OverlayDTO[]
    const [rooftopOverlays, setRooftopOverlays] = useState<OverlayDTO[]>([])

    useEffect(() => {
        const fullOverlays = rooftop.overlays.map((rtOverlay: RooftopOverlay) => {
            const matchingOverlay = overlays.find((apiOverlay: OverlayDTO) => apiOverlay.id === rtOverlay.id)
            if (matchingOverlay) {
                return {
                    ...matchingOverlay,
                    manditory: rtOverlay.manditory,
                    phone_camera_lens: rtOverlay.phone_camera_lens,
                    order: rtOverlay.order
                }
            }
        }) as OverlayDTO[]
        const allOverlays = fullOverlays.filter((overlay: OverlayDTO) => !!overlay)
        setRooftopOverlays(allOverlays)
    }, [rooftop])

    const onDragEnd = async (result: any) => {
        const newOverlayOrder = [...rooftopOverlays]
        const [removed] = newOverlayOrder.splice(result.source.index, 1)
        newOverlayOrder.splice(result.destination.index, 0, removed)

        const newRooftopOverlays = newOverlayOrder.map((overlay: OverlayDTO, index) => {
            return {
                id: overlay.id,
                manditory: overlay.manditory,
                phone_camera_lens: overlay.phone_camera_lens,
                order: index
            }
        }) as RooftopOverlay[]

        handleRooftopChange('overlays', newRooftopOverlays)
    }

    const minHeight = rooftopOverlays.length * 230

    return (
        <div style={{ minHeight }}>
            <Typography variant="h5" gutterBottom>Overlay Settings</Typography>
            <Divider />
            <br />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="overlayDragDrop">
                    {(provided: any, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {rooftopOverlays.sort((a, b) => a.order - b.order).map((overlay, index) => (
                                <Draggable key={overlay.id} draggableId={overlay.id.toString()} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <OverlayCard index={index} overlay={overlay} rooftop={rooftop} handleRooftopChange={handleRooftopChange} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default OverlaySettings;