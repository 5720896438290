import { useEffect, useState } from 'react';
import { Rooftop } from "shared/interfaces/rooftop.interface";
import WizardSettings from 'components/admin/settings/rooftop/wizard-settings/WizardSettings';
import axios from 'axios';
import PhoneCameraSettings from './PhoneCameraSettings';
import LegFoldSettings from './LegFoldSettings';
import AutoscanSettings from './AutoscanSettings';
import PhoneSyncSettings from './PhoneSyncSettings';
import RooftopSettings from './RooftopSettings';
import RooftopPreferences from './RooftopPreferences';
import SubTabs from 'components/shared/SubTabs';
import OverlaySettings from './OverlaySettings';

interface RooftopDetailsProps {
  rooftopState: Rooftop
  handleRooftopChange: (key: string, value: any) => void
  handleWizardChange: (enabledList: any, disabledList: any) => void
}

export default function RooftopDetails({ rooftopState, handleRooftopChange, handleWizardChange }: Readonly<RooftopDetailsProps>) {
  const rooftop = rooftopState

  const [serverVersion, setServerVersion] = useState('')

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get("/api/rooftop/get_version")
        setServerVersion(response.data)
      } catch (err: any) {
        setServerVersion("Could not fetch server version.")
      }
    }

    fetchVersion().catch(console.error)
  }, [])

  const tabs = [
    {
      label: "Settings",
      component:
        <RooftopSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Auto Scan",
      component:
        <AutoscanSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Leg Fold",
      component:
        <LegFoldSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Phone Camera",
      component:
        <PhoneCameraSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Phone Sync",
      component:
        <PhoneSyncSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Preferences",
      component:
        <RooftopPreferences
          rooftop={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Wizard",
      component:
        <WizardSettings 
          rooftop={rooftop} 
          handleWizardChange={handleWizardChange}
        />
    },
    {
      label: "Overlay Order",
      component:
        <OverlaySettings 
          rooftop={rooftop} 
          handleRooftopChange={handleRooftopChange}
        />
    }
  ]

  return (
    <>
      <div className='mb-3 border-b-2'>
        <SubTabs tabs={tabs} />
      </div>
      <div style={{ paddingTop: '1%' }}>{`Version: ${serverVersion}`}</div>
    </>
  )
}
