import React from 'react';
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { Divider, Typography } from '@mui/material';
import BWCheckbox from 'components/shared/BWCheckbox';
import BWNumberField from 'components/shared/BWNumberInput';

interface AutoscanSettingsProps {
  rooftopState: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const AutoscanSettings: React.FC<AutoscanSettingsProps> = ({ rooftopState, handleRooftopChange }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>Auto Scan Settings</Typography>
      <Divider />
      <br />
      <BWNumberField
        label='Autoscan Countdown Length (in seconds)'
        onChange={(value) => handleRooftopChange("auto_scan_countdown_length", value)}
        value={rooftopState.auto_scan_countdown_length}
      />
      <BWNumberField
        label='Manual Scan Countdown Length (in seconds)'
        onChange={(value) => handleRooftopChange("manual_scan_countdown_length", value)}
        value={rooftopState.manual_scan_countdown_length}
      />
      <BWNumberField
        label='Display Screen Sleep Timeout (in seconds)'
        onChange={(value) => handleRooftopChange("display_screen_sleep_timer", value)}
        value={rooftopState.display_screen_sleep_timer}
      />
      <BWCheckbox
        value={rooftopState.auto_scan_enabled}
        onChange={() => handleRooftopChange('auto_scan_enabled', !rooftopState.auto_scan_enabled)} 
        label="Autoscan"
      />
      <BWCheckbox
        value={rooftopState.auto_scan_pano}
        onChange={() => handleRooftopChange('auto_scan_pano', !rooftopState.auto_scan_pano)} 
        label="Show 'Capture 360' Button"
      />
      <BWCheckbox
        value={rooftopState.dual_hand_scan_capture}
        onChange={() => handleRooftopChange('dual_hand_scan_capture', !rooftopState.dual_hand_scan_capture)} 
        label="Simultaneous Interior and Exterior Capture"
      />
      <BWCheckbox
        value={rooftopState.tablet_rooftop_select}
        onChange={() => handleRooftopChange('tablet_rooftop_select', !rooftopState.tablet_rooftop_select)}
        label="Tablet Rooftop Select"
      />
      <div style={{ padding: 5 }}></div>
    </>
  );
};

export default AutoscanSettings;
